<div class="sales-card" [ngClass]="{ loader: viewState.isLoading }">
  <loader size="md" [loading]="viewState.isLoading" *ngIf="viewState.isLoading"></loader>
  <div *ngIf="!viewState.isLoading && viewState.categoryHasProducts" class="sales-card-container">
    <app-last-category-sales-report-card
      [cardData]="cardData"
    ></app-last-category-sales-report-card>
    <div class="separator"></div>
    <div class="product-sales-card-container">
      <div class="product-sales-card-container--products">
        <div *ngFor="let product of viewState.categoryProducts">
          <a
            class="product-sales-card-container__card"
            (click)="
              presenter.emitViewEvent({
                type: 'ProductCardClicked',
                productId: product._id,
                categoryId: product.Category
              })
            "
          >
            <img
              class="product-sales-card-container__card__img"
              src="{{ product.productPicture }}"
            />
            <div class="product-sales-card-container__card__content">
              <p class="caption2--regular content-secondary-color">
                {{ 'LAST_CATEGORY.MIN_PRICE_PROFIT' | translate }}
              </p>
              <p class="body1--bold content-main-color">
                {{ product.productPrice }}{{ 'LAST_CATEGORY.SAR' | translate }}/<span
                  class="body1--bold dark-yellow-color right-gap"
                  >{{ product.productProfit }}{{ 'LAST_CATEGORY.SAR' | translate }}</span
                >
              </p>
            </div>
          </a>
        </div>
      </div>
      <button
        class="show-all-button body1--bold"
        (click)="presenter.emitViewEvent({ type: 'ViewAllClicked', categoryId: this.cardData.categoryId, arabicName:  this.cardData.arabicName,
        englishName: this.cardData.englishName,  })"
      >
        {{ 'LAST_CATEGORY.SHOW_ALL' | translate }}
      </button>
    </div>
  </div>
  <app-empty-last-category
    *ngIf="!viewState.isLoading && !viewState.categoryHasProducts"
  ></app-empty-last-category>
</div>
