import { inject, Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { CheckUserNewExperienceEligibilityUseCase } from 'src/app/core/usecases/products/check-user-new-experience-eligibility.usecase';
import { SetSelectedProductsPageExperienceUseCase } from 'src/app/core/usecases/products/set-selected-products-page-experience.usecase';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { LastCategoryWelcomeCardSideEffect } from './last-category-welcome-card.side-effect';
import { LastCategoryWelcomeCardViewEvent } from './last-category-welcome-card.view-event';
import { LastCategoryWelcomeCardViewState } from './last-category-welcome-card.view-state';

@Injectable()
export class LastCategoryWelcomeCardPresenter extends BasePresenter<
  LastCategoryWelcomeCardViewState,
  LastCategoryWelcomeCardViewEvent,
  LastCategoryWelcomeCardSideEffect
> {
  private _shouldDisplayNewHomePageUseCase = inject(ShouldDisplayNewHomePageUseCase);

  private _checkUserNewExperienceEligibilityUseCase = inject(
    CheckUserNewExperienceEligibilityUseCase,
  );

  private _setSelectedProductsPageExperienceUseCase = inject(
    SetSelectedProductsPageExperienceUseCase,
  );

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): LastCategoryWelcomeCardViewState {
    return {
      shouldDisplay: false,
      userName: '',
      newExperienceIsSelected: false,
    };
  }

  protected onViewEvent(event: LastCategoryWelcomeCardViewEvent): void {
    switch (event.type) {
      case 'Init': {
        const shouldDisplayNewExperience = this._checkUserNewExperienceEligibilityUseCase.execute();
        this.updateViewState({
          ...this.viewState,
          shouldDisplay: shouldDisplayNewExperience,
          userName: user.fullName,
          newExperienceIsSelected:
            shouldDisplayNewExperience && this._shouldDisplayNewHomePageUseCase.execute(),
        });
        break;
      }
      case 'ClickSwitchExperience': {
        if (this.viewState.newExperienceIsSelected) {
          this._logMixpanelEventUseCase.execute({
            eventName: 'discoverability_funnel_old_exp_button',
          });
        }
        this._setSelectedProductsPageExperienceUseCase.execute(
          !this.viewState.newExperienceIsSelected,
        );
        this.updateViewState({
          ...this.viewState,
          newExperienceIsSelected: !this.viewState.newExperienceIsSelected,
        });
        break;
      }
    }
  }
}
