import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-last-category',
  templateUrl: './empty-last-category.component.html',
  styleUrls: ['./empty-last-category.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class EmptyLastCategoryComponent {
  public assetsPath: string = '../../../assets/img/';
}
