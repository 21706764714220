import { NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryItemModel } from 'src/app/core/domain/category-item.model';
import { BaseComponent } from '../../base/base.component';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { LastCategorySalesReportCardPresenter } from './presentation/last-category-sales-report-card.presenter';
import { LastCategorySalesReportCardSideEffect } from './presentation/last-category-sales-report-card.side-effects';
import { LastCategorySalesReportCardViewEvent } from './presentation/last-category-sales-report-card.view-event';
import { LastCategorySalesReportCardViewState } from './presentation/last-category-sales-report-card.view-state';

@Component({
  selector: 'app-last-category-sales-report-card',
  templateUrl: './last-category-sales-report-card.component.html',
  styleUrls: ['./last-category-sales-report-card.component.scss'],
  standalone: true,
  providers: [LastCategorySalesReportCardPresenter],
  imports: [TranslateModule, CurrencyShortNamePipe, CurrencyTranslatePipe, NgClass],
})
export class LastCategorySalesReportCardComponent extends BaseComponent<
  LastCategorySalesReportCardPresenter,
  LastCategorySalesReportCardViewState,
  LastCategorySalesReportCardViewEvent,
  LastCategorySalesReportCardSideEffect
> {
  @Input() shouldDisplayBackground: boolean;

  @Input() cardData: CategoryItemModel;

  assetsRelativePath = '/assets/img/sales-icons/';

  public presenter: LastCategorySalesReportCardPresenter = inject(
    LastCategorySalesReportCardPresenter,
  );

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      shouldDisplayBackground: this.shouldDisplayBackground,
      cardCategoryId: this.cardData?.categoryId,
    });
  }
}
