import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../../base/base.component';
import { LastCategoryProductDetailsComponent } from '../last-category-product-details/last-category-product-details.component';
import { LastCategoryItemCardPresenter } from './presentation/last-category-item-card.presenter';
import { LastCategoryItemCardSideEffect } from './presentation/last-category-item-card.side-effect';
import { LastCategoryItemCardViewEvent } from './presentation/last-category-item-card.view-event';
import { LastCategoryItemCardViewState } from './presentation/last-category-item-card.view-state';

@Component({
  selector: 'app-last-category-item-card',
  templateUrl: './last-category-item-card.component.html',
  styleUrls: ['./last-category-item-card.component.scss'],
  standalone: true,
  imports: [NgFor, TranslateModule, NgIf, NgClass, LastCategoryProductDetailsComponent],
  providers: [LastCategoryItemCardPresenter],
})
export class LastCategoryItemCardComponent extends BaseComponent<
  LastCategoryItemCardPresenter,
  LastCategoryItemCardViewState,
  LastCategoryItemCardViewEvent,
  LastCategoryItemCardSideEffect
> {
  public assetsPath = '../../../assets/img/';

  public presenter: LastCategoryItemCardPresenter = inject(LastCategoryItemCardPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }
}
