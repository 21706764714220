import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetCommercialCategoryDataByIdUseCase } from 'src/app/core/usecases/get-commercial-category-data-by-id-usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { LastCategorySalesReportCardSideEffect } from './last-category-sales-report-card.side-effects';
import { LastCategorySalesReportCardViewEvent } from './last-category-sales-report-card.view-event';
import { LastCategorySalesReportCardViewState } from './last-category-sales-report-card.view-state';

@Injectable()
export class LastCategorySalesReportCardPresenter extends BasePresenter<
  LastCategorySalesReportCardViewState,
  LastCategorySalesReportCardViewEvent,
  LastCategorySalesReportCardSideEffect
> {
  private _getCommercialCategoryDataByIdUseCase = inject(GetCommercialCategoryDataByIdUseCase);

  private _multitenancyService = inject(MultitenancyService);

  private _router = inject(ActivatedRoute);

  protected defaultViewState(): LastCategorySalesReportCardViewState {
    return {
      numberOfOrders: '',
      profit: '',
      merchants: '',
      confirmationRate: '',
      deliveryRate: '',
      currency: '',
    };
  }

  protected onViewEvent(event: LastCategorySalesReportCardViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.add(
          this._router.params.subscribe((params) => {
            const categoryId = event.cardCategoryId ? event.cardCategoryId : params.id;
            this._getCommercialCategoryDataByIdUseCase.execute(categoryId).subscribe((data) => {
              this.updateViewState({
                ...this.viewState,
                numberOfOrders: data.sales!.numberOfOrders,
                profit: data.sales!.profitPerMerchant,
                merchants: data.sales!.numberOfMerchants,
                confirmationRate: data.sales!.confirmationRate,
                deliveryRate: data.sales!.deliveryRate,
                currency: this._multitenancyService.getCurrentCountry().currency.arabicName,
              });
            });
          }),
        );
        break;
      }
    }
  }
}
