import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetBottomLevelCategoriesUseCase } from 'src/app/core/usecases/get-bottom-level-categories.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { LastCategoryItemCardSideEffect } from './last-category-item-card.side-effect';
import { LastCategoryItemCardViewEvent } from './last-category-item-card.view-event';
import { LastCategoryItemCardViewState } from './last-category-item-card.view-state';

@Injectable()
export class LastCategoryItemCardPresenter extends BasePresenter<
  LastCategoryItemCardViewState,
  LastCategoryItemCardViewEvent,
  LastCategoryItemCardSideEffect
> {
  constructor(
    private _getBottomLevelCategoriesUseCase: GetBottomLevelCategoriesUseCase,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    super();
  }

  protected defaultViewState(): LastCategoryItemCardViewState {
    return {
      categoryItems: [],
      selectedCategoryIndex: '',
      englishLanguageSelected: true,
    };
  }

  protected onViewEvent(event: LastCategoryItemCardViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          englishLanguageSelected:
            this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE,
        });

        this.add(
          this._translateService.onLangChange.subscribe({
            next: (ev: LangChangeEvent) => {
              this.updateViewState({
                ...this.viewState,
                englishLanguageSelected: ev.lang === ENGLISH_LANGUAGE,
              });
            },
          }),
        );

        this.add(
          this._getBottomLevelCategoriesUseCase.execute().subscribe((categories) => {
            this.updateViewState({
              ...this.viewState,
              categoryItems: categories,
            });
          }),
        );

        break;
      }

      case 'ItemCardClicked': {
        if (event.index.toString() === this.viewState.selectedCategoryIndex) {
          this.updateViewState({ ...this.viewState, selectedCategoryIndex: '' });
        } else {
          this.updateViewState({
            ...this.viewState,
            selectedCategoryIndex: event.index.toString(),
          });
          this._logMixpanelEventUseCase.execute({
            eventName: 'discoverability_funnel_show_sales_report',
            payload: { CATEGORY_ID: event.categoryId },
          });
        }
        break;
      }
    }
  }
}
