import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LastCategoryItemCardComponent } from './last-category-item-card/last-category-item-card.component';

@Component({
  selector: 'app-last-category',
  templateUrl: './last-category.component.html',
  styleUrls: ['./last-category.component.scss'],
  standalone: true,
  imports: [TranslateModule, LastCategoryItemCardComponent],
})
export class LastCategoryComponent {}
