<div class="item-cards-container">
  <div *ngFor="let categoryItem of viewState.categoryItems; let index = index">
    <div
      class="whole-card-container"
      [ngClass]="{ 'bottom-gap': viewState.selectedCategoryIndex === index.toString() }"
    >
      <div
        class="category-item-card"
        (click)="
          presenter.emitViewEvent({
            type: 'ItemCardClicked',
            index: index,
            categoryId: categoryItem.categoryId
          })
        "
        [ngClass]="{ 'active-card': viewState.selectedCategoryIndex === index.toString() }"
      >
        <img class="category-item-card__img" src="{{ categoryItem.imageUrl }}" />
        <div class="category-item-card__content">
          <p class="body1--bold content-main-color">
            {{
              viewState.englishLanguageSelected ? categoryItem.englishName : categoryItem.arabicName
            }}
          </p>
          <div class="category-item-card__content__text">
            <div class="category-item-card__content__text" *ngIf="categoryItem?.sales">
              <p class="body2--regular text-brand-color">
                {{ categoryItem?.sales?.numberOfOrders || '-'
                }}<span class="body2--regular content-medium-color right-gap">{{
                  'LAST_CATEGORY.ORDER' | translate
                }}</span>
              </p>
              <p class="body2--regular text-brand-color">
                {{ categoryItem?.sales?.numberOfMerchants || '-'
                }}<span class="body2--regular content-medium-color right-gap">{{
                  'LAST_CATEGORY.MERCHANT' | translate
                }}</span>
              </p>
            </div>
            <img src="{{ assetsPath + 'down-arrow.svg' }}" />
          </div>
        </div>
      </div>
      <div *ngIf="viewState.selectedCategoryIndex === index.toString()" class="triangle"></div>
      <app-last-category-product-details
        [cardData]="categoryItem"
        *ngIf="viewState.selectedCategoryIndex === index.toString()"
      />
    </div>
  </div>
</div>
