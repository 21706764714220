import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SORT_BY_INTRODUCED_AT } from 'src/app/presentation/shared/constants/category-products';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { LastCategoryProductDetailsSideEffects } from './last-category-product-details.side-effect';
import { LastCategoryProductDetailsViewEvents } from './last-category-product-details.view-event';
import { LastCategoryProductDetailsViewState } from './last-category-product-details.view-state';

@Injectable()
export class LastCategoryProductDetailsPresenter extends BasePresenter<
  LastCategoryProductDetailsViewState,
  LastCategoryProductDetailsViewEvents,
  LastCategoryProductDetailsSideEffects
> {
  private _productService = inject(ProductService);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): LastCategoryProductDetailsViewState {
    return {
      isLoading: true,
      categoryHasProducts: false,
      categoryProducts: [],
    };
  }

  protected onViewEvent(event: LastCategoryProductDetailsViewEvents): void {
    switch (event.type) {
      case 'Init': {
        const filters = {
          page: 1,
          pageSize: 4,
          sortBy: SORT_BY_INTRODUCED_AT,
          commercialCategoryId: event.categoryId,
        };
        this._productService
          .getProductsForCategory(filters)
          .pipe(
            map((res) => {
              return res.results.map((variant: any) => variant.primaryVariant);
            }),
          )
          .subscribe({
            next: (res) => {
              this.updateViewState({
                ...this.viewState,
                isLoading: false,
                categoryHasProducts: res.length !== 0,
                categoryProducts: res,
              });
            },
          });
        break;
      }
      case 'ViewAllClicked': {
        const queryParams = {
          currentPage: 1,
          items: 12,
          sorting: SORT_BY_INTRODUCED_AT,
          discoverability: true,
        };
        this.emitSideEffect({
          type: 'NavigateToAllCategoryProducts',
          categoryId: event.categoryId,
          queryParams,
        });
        this._logMixpanelEventUseCase.execute({
          eventName: 'discoverability_funnel_go_to_category',
          payload: {
            category_name_ar: event.arabicName,
            category_name_en: event.englishName,
          },
        });
        break;
      }
      case 'ProductCardClicked': {
        const queryParams = {
          discoverability: true,
        };
        this.emitSideEffect({
          type: 'NavigateToProductDetails',
          productId: event.productId,
          queryParams,
        });
        this._logMixpanelEventUseCase.execute({
          eventName: 'discoverability_funnel_product_click',
          payload: {
            product_id: event.productId,
            category: event.categoryId,
          },
        });
        break;
      }
    }
  }
}
