import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryItemModel } from 'src/app/core/domain/category-item.model';
import { BaseComponent } from '../../base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { PRODUCT_DETAILS } from '../../shared/constants';
import { LastCategorySalesReportCardComponent } from '../last-category-sales-report-card/last-category-sales-report-card.component';
import { EmptyLastCategoryComponent } from './empty-last-category/empty-last-category.component';
import { LastCategoryProductDetailsPresenter } from './presenter/last-category-product-details.presenter';
import { LastCategoryProductDetailsSideEffects } from './presenter/last-category-product-details.side-effect';
import { LastCategoryProductDetailsViewEvents } from './presenter/last-category-product-details.view-event';
import { LastCategoryProductDetailsViewState } from './presenter/last-category-product-details.view-state';

@Component({
  selector: 'app-last-category-product-details',
  templateUrl: './last-category-product-details.component.html',
  styleUrls: ['./last-category-product-details.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgFor,
    NgIf,
    NgClass,
    LastCategorySalesReportCardComponent,
    RouterLink,
    LoaderComponent,
    EmptyLastCategoryComponent,
  ],
  providers: [LastCategoryProductDetailsPresenter],
})
export class LastCategoryProductDetailsComponent extends BaseComponent<
  LastCategoryProductDetailsPresenter,
  LastCategoryProductDetailsViewState,
  LastCategoryProductDetailsViewEvents,
  LastCategoryProductDetailsSideEffects
> {
  @Input() cardData: CategoryItemModel;

  public presenter: LastCategoryProductDetailsPresenter = inject(
    LastCategoryProductDetailsPresenter,
  );

  private _router: Router = inject(Router);

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init', categoryId: this.cardData.categoryId });
  }

  onSideEffect(sideEffect: LastCategoryProductDetailsSideEffects): void {
    switch (sideEffect.type) {
      case 'NavigateToAllCategoryProducts': {
        this._router.navigate(['/', 'products', 'category', sideEffect.categoryId], {
          queryParams: sideEffect.queryParams,
        });
        break;
      }
      case 'NavigateToProductDetails': {
        this._router.navigate([PRODUCT_DETAILS, sideEffect.productId], {
          queryParams: sideEffect.queryParams,
        });
        break;
      }
    }
  }
}
