import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../../base/base.component';
import { LastCategoryWelcomeCardPresenter } from './presenter/last-category-welcome-card.presenter';
import { LastCategoryWelcomeCardSideEffect } from './presenter/last-category-welcome-card.side-effect';
import { LastCategoryWelcomeCardViewEvent } from './presenter/last-category-welcome-card.view-event';
import { LastCategoryWelcomeCardViewState } from './presenter/last-category-welcome-card.view-state';

@Component({
  selector: 'app-last-category-welcome-card',
  templateUrl: './last-category-welcome-card.component.html',
  styleUrls: ['./last-category-welcome-card.component.scss'],
  standalone: true,
  providers: [LastCategoryWelcomeCardPresenter],
  imports: [TranslateModule, NgIf, NgClass],
})
export class LastCategoryWelcomeCardComponent extends BaseComponent<
  LastCategoryWelcomeCardPresenter,
  LastCategoryWelcomeCardViewState,
  LastCategoryWelcomeCardViewEvent,
  LastCategoryWelcomeCardSideEffect
> {
  public presenter: LastCategoryWelcomeCardPresenter = inject(LastCategoryWelcomeCardPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }
}
