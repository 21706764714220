<div class="sales" [ngClass]="{ 'card-background': shouldDisplayBackground }">
  <div class="sales-column">
    <p class="caption2--bold secondary-dark">
      {{ 'LAST_CATEGORY.SALES.LAST_THREE_MONTHS' | translate }}
    </p>
    <div class="sales-row">
      <div class="sales--data">
        <div class="sales--data--item">
          <img src="{{ assetsRelativePath + 'orders.svg' }}" alt="orders icon" />
          <div class="sales--data--item--text">
            <p class="body2--bold color-main">
              {{ viewState.numberOfOrders }}
              <span class="body2--regular">{{ 'LAST_CATEGORY.SALES.ORDER' | translate }}</span>
            </p>
            <p class="caption2--medium color-medium">
              {{ 'LAST_CATEGORY.SALES.AVERAGE_ORDERS' | translate }}
            </p>
          </div>
        </div>
        <div class="sales--data--item">
          <img src="{{ assetsRelativePath + 'profits.svg' }}" alt="profits icon" />
          <div class="sales--data--item--text">
            <p class="body2--bold color-main">
              {{ viewState.profit }}
              <span class="body2--regular">
                {{ viewState.currency | currencyTranslate | translate | currencyShortName }}
              </span>
            </p>
            <p class="caption2--medium color-medium">
              {{ 'LAST_CATEGORY.SALES.AVERAGE_PROFIT' | translate }}
            </p>
          </div>
        </div>
        <div class="sales--data--item">
          <img src="{{ assetsRelativePath + 'merchants.svg' }}" alt="merchants icon" />
          <div class="sales--data--item--text">
            <p class="body2--bold color-main">
              {{ viewState.merchants || '-' }}
              <span class="body2--regular">{{ 'LAST_CATEGORY.SALES.MERCHANT' | translate }} </span>
            </p>
            <p class="caption2--medium color-medium">
              {{ 'LAST_CATEGORY.SALES.MERCHANTS' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="sales--cr-dr">
        <div>
          <p class="body2--bold color-main">{{ viewState.confirmationRate || '-' }}</p>
          <p class="caption2--medium color-medium">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.CONFIRMATION_RATE' | translate }}
          </p>
        </div>
        <div>
          <p class="body2--bold color-main">{{ viewState.deliveryRate || '-' }}</p>
          <p class="caption2--medium color-medium">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.DELIVERY_RATE' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
