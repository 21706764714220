<div
  class="welcome-card"
  *ngIf="viewState.shouldDisplay"
  [ngClass]="{ 'display-new-card': !viewState.newExperienceIsSelected }"
>
  <div class="greeting" *ngIf="viewState.newExperienceIsSelected">
    <img src="/assets/img/hi.svg" alt="" class="greeting--img" />
    <div>
      <p class="body1--bold color-main">
        {{ 'HEADER.PROFILE_DROPDOWN.SALUTATION' | translate }}{{ viewState.userName }}
      </p>
      <p class="caption1--regular color-medium">{{ 'LAST_CATEGORY.INTRO' | translate }}</p>
    </div>
  </div>
  <button
    class="return-to-main caption1--bold"
    (click)="presenter.emitViewEvent({ type: 'ClickSwitchExperience' })"
    *ngIf="viewState.newExperienceIsSelected"
  >
    {{ 'LAST_CATEGORY.RETURN_TO_MAIN' | translate }}
  </button>
  <button
    class="return-to-new-main caption1--bold"
    (click)="presenter.emitViewEvent({ type: 'ClickSwitchExperience' })"
    *ngIf="!viewState.newExperienceIsSelected"
  >
    <img src="assets/img/new.svg" />
    {{ 'LAST_CATEGORY.RETURN_TO_NEW_MAIN' | translate }}
  </button>
</div>
